<template>
  <b-container fluid class="antiscam py-2">
    <div class="antiscam__header d-flex align-items-center justify-content-between py-2">
      <div class="flex-grow-1 mr-1">
        <h5 v-shtml="$t('fSetAscam_total', { tot: total })" />
      </div>
      <div class="action-wrapper">
        <!--
        <b-btn
          variant="secondary"
          size="sm"
          class="ml-1"
          :disabled="scamInfo && scamInfo.tot === 0"
          @click="onScamProcess"
        >
          {{ $t('fSetAscam_process') }}
        </b-btn>
        -->
        <b-btn
          variant="success"
          size="sm"
          :disabled="isActionActive"
          @click="onSave"
        >
          {{ $t('fSetAscam_save') }}
        </b-btn>
      </div>
    </div>
    <b-row class="antiscam__body">
      <b-col lg="6" md="6" cols="12" class="pt-2">
        <b-form-group
          id="settings-verify"
          label-for="settings-verify-input"
          :label="$t('fSetAscam_f_verify', { tot: verifyWordsCount })"
          :description="$t('fSetAscam_f_verify_desc')"
          label-size="sm"
          class="w-100 px-2"
        >
          <b-form-textarea
            id="settings-verify-input"
            ref="verify"
            v-model="verify"
            @input="onChangeVerify"
          />
        </b-form-group>
        <div class="w-100 px-2">
          <b-btn
            v-for="item in verifyBadges"
            :key="item.label"
            :title="$t('fSetAscam_match_ident')"
            class="ml-1"
            variant="warning"
            size="sm"
            @click="onMessagesPreview(item.label)"
          >
            {{ item.label }} <b-badge>{{ item.value }}</b-badge>
          </b-btn>
          <b-btn
            v-for="item in verifyPossibleBadges"
            :key="item.label"
            :title="$t('fSetAscam_match_possible')"
            class="ml-1"
            variant="outline-warning"
            size="sm"
            @click="onMessagesPreview(item.label)"
          >
            {{ item.label }} <b-badge>{{ item.value }}</b-badge>
          </b-btn>
        </div>
      </b-col>
      <b-col lg="6" md="6" cols="12" class="pt-2">
        <b-form-group
          id="settings-block"
          label-for="settings-block-input"
          :label="$t('fSetAscam_f_block', { tot: blockWordsCount })"
          :description="$t('fSetAscam_f_block_desc')"
          label-size="sm"
          class="w-100 px-2"
        >
          <b-form-textarea
            id="settings-block-input"
            ref="block"
            v-model="block"
            @input="onChangeBlock"
          />
        </b-form-group>
        <div class="w-100 px-2">
          <b-btn
            v-for="item in blockBadges"
            :key="item.label"
            :title="$t('fSetAscam_match_ident')"
            class="ml-1"
            variant="danger"
            size="sm"
            @click="onMessagesPreview(item.label)"
          >
            {{ item.label }} <b-badge>{{ item.value }}</b-badge>
          </b-btn>
          <b-btn
            v-for="item in blockPossibleBadges"
            :key="item.label"
            :title="$t('fSetAscam_match_possible')"
            class="ml-1"
            variant="outline-danger"
            size="sm"
            @click="onMessagesPreview(item.label)"
          >
            {{ item.label }} <b-badge>{{ item.value }}</b-badge>
          </b-btn>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'AntiScamForm',
  data () {
    return {
      verify: '',
      block: ''
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      errors: 'repos/errors',
      scamInfo: 'forms/settings/antiscam/scamInfo'
    }),
    verifyWords: {
      get () {
        const rawData = this.getByName('settings/index')
        return (rawData?.verify_words?.length ? rawData.verify_words : []).join('\n')
      }
    },
    blockWords: {
      get () {
        const rawData = this.getByName('settings/index')
        return (rawData?.block_words?.length ? rawData.block_words : []).join('\n')
      }
    },
    verifyWordsCount () {
      const rawData = this.getByName('settings/index')
      return rawData?.verify_words?.length || 0
    },
    blockWordsCount () {
      const rawData = this.getByName('settings/index')
      return rawData?.block_words?.length || 0
    },
    isActionActive () {
      const rawData = this.getByName('settings/index')
      const newValueVerify = this.verify.trim().split('\n').filter(w => !!w)
      const newValueBlock = this.block.trim().split('\n').filter(w => !!w)
      const oldValueVerify = (rawData && rawData.verify_words ? rawData.verify_words : [])
      const oldValueBlock = (rawData && rawData.block_words ? rawData.block_words : [])
      if (
        JSON.stringify(newValueVerify).hashCode() !== JSON.stringify(oldValueVerify).hashCode() ||
        JSON.stringify(newValueBlock).hashCode() !== JSON.stringify(oldValueBlock).hashCode()
      ) {
        return false
      }
      return true
    },
    total () {
      return this.scamInfo?.tot || 0
    },
    blockBadges () {
      const badges = this.scamInfo?.block_items || {}
      const result = []
      Object.keys(badges).forEach(key => result.push({ label: key, value: badges[key] }))
      return result
    },
    verifyBadges () {
      const badges = this.scamInfo?.verify_items || {}
      const result = []
      Object.keys(badges).forEach(key => result.push({ label: key, value: badges[key] }))
      return result
    },
    blockPossibleBadges () {
      const badges = this.scamInfo?.block_possible_items || {}
      const result = []
      Object.keys(badges).forEach(key => result.push({ label: key, value: badges[key] }))
      return result
    },
    verifyPossibleBadges () {
      const badges = this.scamInfo?.verify_possible_items || {}
      const result = []
      Object.keys(badges).forEach(key => result.push({ label: key, value: badges[key] }))
      return result
    }
  },
  watch: {
    verifyWords (n) {
      this.verify = n
      this.$nextTick(() => {
        this.calcHeight('verify')
      })
    },
    blockWords (n) {
      this.block = n
      this.$nextTick(() => {
        this.calcHeight('block')
      })
    }
  },
  mounted () {
    this.verify = this.verifyWords
    this.block = this.blockWords
    this.$nextTick(() => {
      this.calcHeight('verify')
      this.calcHeight('block')
      this.getScamInfo()
    })
  },
  methods: {
    ...mapActions({
      call: 'repos/call',
      flush: 'repos/flush',
      flushError: 'repos/flushError',
      getScamInfo: 'forms/settings/antiscam/scamInfo'
      // , scamProcess: 'forms/settings/antiscam/scamProcess'
    }),
    onChangeVerify () {
      this.$nextTick(() => {
        this.calcHeight('verify')
      })
    },
    onChangeBlock () {
      this.$nextTick(() => {
        this.calcHeight('block')
      })
    },
    calcHeight (ref) {
      const el = this.$refs[ref]?.$el
      if (el) {
        el.style.height = ''
        const newHeight = el.scrollHeight
        el.style.height = (newHeight > 500 ? 500 : newHeight) + 'px'
      }
    },
    async onSave () {
      const payload = {}
      const rawData = this.getByName('settings/index')
      const newValueVerify = this.verify.trim().split('\n').filter(w => !!w)
      const newValueBlock = this.block.trim().split('\n').filter(w => !!w)
      const oldValueVerify = (rawData && rawData.verify_words ? rawData.verify_words : [])
      const oldValueBlock = (rawData && rawData.block_words ? rawData.block_words : [])
      if (JSON.stringify(newValueVerify).hashCode() !== JSON.stringify(oldValueVerify).hashCode()) {
        payload.verify_words = newValueVerify
      }
      if (JSON.stringify(newValueBlock).hashCode() !== JSON.stringify(oldValueBlock).hashCode()) {
        payload.block_words = newValueBlock
      }
      if (Object.keys(payload).length > 0) {
        await this.call({
          repo: 'settings',
          method: 'store',
          payload
        })
        await this.getScamInfo()
      }
    },
    // async onScamProcess () {
    //   await this.scamProcess()
    // },
    onMessagesPreview (key) {
      if (!isServer) {
        window.open(`/messages?q=${key}`, '_blank').focus()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.antiscam {
  &__header {}
  &__body {
    overflow: hidden;
    overflow-y: auto;
    height: calc(100svh - 130px);
  }
}
</style>
