<template>
  <b-container
    fluid
    class="d-flex flex-column"
  >
    <b-nav tabs>
      <b-nav-item
        v-for="tab in tabs"
        :key="tab"
        :active="active === tab"
        @click="setActive(tab)"
      >
        {{ $t(`pSet_tab_${tab}`) }}
      </b-nav-item>
    </b-nav>
    <payments v-if="active === 'payments'" />
    <hidden-profiles v-if="active === 'hidden_profiles'" />
  </b-container>
</template>

<script>
import Payments from './SettingsForm/Payments'
import HiddenProfiles from './SettingsForm/HiddenProfiles'

export default {
  name: 'SettingsForm',
  components: { HiddenProfiles, Payments },
  data () {
    return {
      active: 'payments',
      tabs: [
        'payments', // , 'local'
        'hidden_profiles'
      ]
    }
  },
  methods: {
    setActive (page) {
      this.active = page
    }
  }
}
</script>

<style scoped>

</style>
