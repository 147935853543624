<template>
  <b-container fluid class="py-2">
    <div class="d-flex align-items-center justify-content-between py-2">
      <div class="flex-grow-1 mr-1">
        <h5 v-shtml="$t('fSetHdPrf_title', { tot: total })" />
      </div>
      <div class="action-wrapper" />
    </div>

    <data-search
      v-model="state"
      :query="searchQuery"
      :list="searchList"
      :is-busy="searchBusy"
      :placeholder="searchPlaceholder"
      @change-query="onSearchQuery"
      @search="onSearch"
      @flush-results="onFlushSearchResults"
    >
      <template
        #autocomplete-item="d"
      >
        <div
          v-for="(item, i) in d.data.items"
          :key="i"
          class="dropdown-item border-bottom px-2 w-100"
          @click="onSelect(item)"
        >
          <div class="overflow-hidden text-truncate">
            <user-profile-item
              :user="item"
              @click="onSelect(item)"
            />
          </div>
        </div>
      </template>
    </data-search>

    <b-table-simple hover small caption-top stacked class="mt-4 w-100">
      <b-tr
        v-for="(item, i) in profiles"
        :key="i"
      >
        <b-td>
          <user-profile-item
            :user="item"
          />
        </b-td>
        <b-td>
          <b-btn
            size="sm"
            variant="outline-danger"
            @click="onRemove(item)"
          >
            <font-awesome-icon :icon="['fas', 'xmark']" />
          </b-btn>
        </b-td>
      </b-tr>
    </b-table-simple>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash/function'

export default {
  name: 'HiddenProfiles',
  data () {
    return {
      state: true,
      searchBusy: false,
      searchQuery: '',
      searchPlaceholder: this.$t('fSetHdPrf_placeholder')
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      profiles: 'forms/settings/hiddenProfiles/profiles',
      searchList: 'forms/settings/hiddenProfiles/searchList'
    }),
    total () {
      return this.profiles?.length || 0
    }
  },
  mounted () {
    this.fetchProfiles()
  },
  methods: {
    ...mapActions({
      call: 'repos/call',
      flush: 'repos/flush',
      flushError: 'repos/flushError',
      fetchProfiles: 'forms/settings/hiddenProfiles/index',
      searchProfile: 'forms/settings/hiddenProfiles/search',
      flushSearch: 'forms/settings/hiddenProfiles/flushSearch'
    }),
    async onSearch (query) {
      this.searchQuery = query
      if (this.searchQuery !== '') {
        await this.searchProfile(this.searchQuery)
      } else {
        this.flushSearch()
      }
    },
    onSearchQuery: debounce(async function (q) {
      this.isSelected = false
      if (escape(q) !== '') {
        try {
          this.searchBusy = true
          await this.searchProfile(escape(q))
        } finally {
          this.searchBusy = false
        }
      } else {
        this.flushSearch()
      }
    }, 300),
    onFlushSearchResults () {
      this.flushSearch()
    },
    async onSelect (profile) {
      const rawData = this.getByName('settings/index')
      const payload = {
        hidden_profiles: JSON.parse(JSON.stringify((rawData && rawData.hidden_profiles) || []))
      }
      if (typeof payload.hidden_profiles === 'object') {
        payload.hidden_profiles = Object.values(payload.hidden_profiles)
      }
      payload.hidden_profiles.push(profile.user_id)
      await this.call({
        repo: 'settings',
        method: 'store',
        payload
      })
      this.flushSearch()
      this.searchQuery = ''
      this.$forceUpdate()
      this.$nextTick(() => {
        this.fetchProfiles()
      })
    },
    async onRemove (profile) {
      const userId = parseInt(profile?.user_id) || 0
      const rawData = this.getByName('settings/index')
      const payload = {
        hidden_profiles: JSON.parse(JSON.stringify((rawData && rawData.hidden_profiles) || []))
      }
      if (typeof payload.hidden_profiles === 'object') {
        payload.hidden_profiles = Object.values(payload.hidden_profiles)
      }
      payload.hidden_profiles = payload.hidden_profiles.filter((p) => {
        return parseInt(p) !== userId
      })
      await this.call({
        repo: 'settings',
        method: 'store',
        payload
      })
      this.flushSearch()
      this.searchQuery = ''
      this.$forceUpdate()
      this.$nextTick(() => {
        this.fetchProfiles()
      })
    }
  }
}
</script>

<style scoped>

</style>
