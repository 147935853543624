<template>
  <b-container fluid class="p-0 p-lg-2">
    <div class="px-1 px-lg-3 my-3 d-flex flex-row">
      <div
        class="h5 mr-1 mr-lg-3"
      >
        {{ $t('pSetPayment_activeGates') }}:
      </div>
      <div class="d-flex flex-row">
        <span
          v-for="(gate, i) in activeGates"
          :key="i"
          class="d-flex flex-row align-items-center justify-content-between mr-2"
        >
          <b-form-checkbox
            v-model="paymentGates"
            :value="gate"
            size="sm"
            switch
          >
            {{ gate }}
          </b-form-checkbox>
        </span>
      </div>
    </div>
    <item-list
      ref="il"
      repo="settings.payments"
      class="text-nowrap px-1 px-lg-2"
      :list-fields="listFields"
      :filter-fields="filterFields"
      :edit-fields="editFields"
      :filter="filter"
      :order="order"
      :can-create="true"
      :can-edit="true"
      :can-restore="true"
      :can-search="false"
      :set-item-variant="setItemVariant"
      card-collapse
      @on-sort="onSort"
      @on-filter="onFilter"
      @on-form-close="onFormClose"
    >
      <template #top-bar="">
        <div class="h5">
          {{ $t('pSetPayment_payAccounts') }}
        </div>
      </template>

      <template #card(header)="{ data: { item }}">
        <div v-if="item">
          <strong class="mr-1">{{ item.type }}</strong>
          <span class="text-secondary">{{ item.name }}</span>
        </div>
        <div v-if="item" class="small d-block text-secondary">
          {{ $dateFormat(item.updated_at) }}
        </div>
        <div v-if="item">
          <b-badge
            v-if="item.is_active === '1'"
            variant="success"
          >
            active
          </b-badge>
          <b-badge
            v-if="item.is_fallback === '1'"
            variant="success"
          >
            fallback
          </b-badge>
        </div>
      </template>

      <template #[`cell(is_active)`]="data">
        <div class="d-block text-center">
          <b-badge
            v-if="data"
            :variant="data.data.item.is_active === '1' ? 'success' : 'danger'"
          >
            {{ data.data.item.is_active === '1' ? $t('eLt_btnYes') : $t('eLt_btnNo') }}
          </b-badge>
        </div>
      </template>
      <template #[`cell(is_fallback)`]="data">
        <div class="d-block text-center">
          <b-badge
            v-if="data"
            :variant="data.data.item.is_fallback === '1' ? 'success' : 'danger'"
          >
            {{ data.data.item.is_fallback === '1' ? $t('eLt_btnYes') : $t('eLt_btnNo') }}
          </b-badge>
        </div>
      </template>
    </item-list>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Payments',
  data () {
    return {
      filter: [],
      order: {
        by: 'payment_accounts.id',
        dir: 'DESC'
      },
      activeGates: ['paypal', 'stripe']
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      errors: 'repos/errors'
    }),
    paymentGates: {
      get () {
        const rawData = this.getByName('settings/index')
        return rawData && rawData.payment_gates ? rawData.payment_gates : []
      },
      async set (value) {
        const payload = {
          payment_gates: value
        }
        await this.call({
          repo: 'settings',
          method: 'store',
          payload
        })
      }
    },
    listFields () {
      return [
        {
          key: 'id',
          label: this.$t('pSetPayment_f_id'),
          sortable: true,
          thClass: 'col-1'
        },
        {
          key: 'is_active',
          label: this.$t('pSetPayment_f_isActive'),
          sortable: true,
          thClass: 'col-1 text-center'
        },
        {
          key: 'is_fallback',
          label: this.$t('pSetPayment_f_isFallback'),
          sortable: true,
          thClass: 'col-1 text-center'
        },
        {
          key: 'name',
          label: this.$t('pSetPayment_f_name'),
          sortable: true,
          thClass: 'col-1 text-center'
        },
        {
          key: 'payments_total',
          label: this.$t('pSetPayment_f_paymentsTotal'),
          sortable: true,
          thClass: 'col-1 text-center',
          class: 'text-center'
        },
        {
          key: 'type',
          label: this.$t('pSetPayment_f_type'),
          sortable: true,
          class: 'col-1 text-center'
        },
        {
          key: 'created_at',
          label: this.$t('pSetPayment_f_createdAt'),
          sortable: true,
          formatter: (value) => {
            return value ? this.$dateFormat(value) : value
          },
          class: 'col-1 text-center'
        },
        {
          key: 'updated_at',
          label: this.$t('pSetPayment_f_updatedAt'),
          sortable: true,
          formatter: (value) => {
            return value ? this.$dateFormat(value) : value
          },
          class: 'col-1 text-center'
        },
        {
          key: 'deleted_at',
          label: this.$t('pSetPayment_f_deletedAt'),
          sortable: true,
          formatter: (value) => {
            return value ? this.$dateFormat(value) : value
          },
          class: 'col-1 text-center'
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thClass: 'col-2',
          tdClass: 'text-right'
        }
      ]
    },
    filterFields () {
      return [
        {
          label: this.$t('pSetPayment_s_type'),
          key: 'type',
          type: 'select',
          class: 'col-12 col-md-6 col-lg-2'
        },
        {
          label: this.$t('pSetPayment_s_name'),
          key: 'name',
          type: 'input',
          class: 'col-12 col-md-6 col-lg-2'
        }
      ]
    },
    editFields () {
      return [
        {
          key: 'name',
          label: this.$t('fSetPayment_f_name'),
          type: 'input',
          class: 'col-12 col-md-6 col-lg-3'
        },
        {
          key: 'type',
          label: this.$t('fSetPayment_f_type'),
          type: 'select',
          class: 'col-12 col-md-6 col-lg-3'
        },
        {
          key: 'is_active',
          label: this.$t('fSetPayment_f_isActive'),
          type: 'select',
          class: 'col-12 col-md-6 col-lg-3'
        },
        {
          key: 'is_fallback',
          label: this.$t('fSetPayment_f_isFallback'),
          type: 'select',
          class: 'col-12 col-md-6 col-lg-3'
        },
        {
          key: 'pub_key',
          label: this.$t('fSetPayment_f_pubKey'),
          type: 'textarea',
          class: 'col-12'
        },
        {
          key: 'prv_key',
          label: this.$t('fSetPayment_f_prvKey'),
          type: 'textarea',
          class: 'col-12'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      call: 'repos/call',
      flush: 'repos/flush',
      flushError: 'repos/flushError'
    }),
    onSort () {},
    onFilter () {},
    onFormClose () {
      this.flush({
        repo: 'settings.payments',
        method: 'show'
      })
    },
    setItemVariant (item) {
      let classStr = ''
      if (!this.paymentGates.includes(item.type)) {
        classStr = 'warning'
      }
      if (item.deleted_at) {
        classStr = 'danger'
      }
      return classStr
    }
  }
}
</script>

<style scoped>

</style>
